import React, { useState } from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";
import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrows } from "@fortawesome/pro-light-svg-icons";

export const fragment = graphql`
  fragment DownloadBar on WpPage_Pagesections_Sections_DownloadBar {
    items {
      label
      file {
        id
        localFile {
          url
        }
      }
    }
  }
`;

const DownloadBar = ({ items }) => {
  if (!items) return null;

  const cols = `grid-cols-${items.length + 1}`;

  return (
    <div className="bg-grey text-white">
      <div
        className={`lg:container mx-auto mt-auto grid ${cols} lg:grid-cols-3 border-t-2 border-white border-opacity-10 font-second`}
      >
        {items.map((item, i) => {
          return (
            <div
              key={i}
              className={`${
                i !== 0
                  ? "border-t-2 md:border-t-0 md:border-l-2 border-opacity-10 border-white"
                  : ""
              }`}
            >
              {item.file.localFile && (
                <ButtonLink
                  text={item.label}
                  url={item.file.localFile.url}
                  classes="py-6 px-6 md:px-12"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DownloadBar;
