import * as React from "react";
import { graphql } from "gatsby";
import Testimonial from "../components/Testimonial";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import Arrow from "../components/Arrow";

SwiperCore.use([Navigation]);

export const fragment = graphql`
  fragment Testimonials on WpPage_Pagesections_Sections_Testimonials {
    items {
      company
      name
      quote
    }
  }
`;

const Testimonials = ({ items }) => {
  if (items.length > 1) {
    // Multiple testimonials are sliders
    return (
      <Swiper
        className="lg:container mx-auto"
        spaceBetween={0}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        slidesPerView={1}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Testimonial {...item} />
            </SwiperSlide>
          );
        })}
        <div className="swiper-nav absolute z-10 bottom-20 right-20 grid grid-cols-2 gap-4">
          <div className="swiper-prev cursor-pointer">
            <Arrow direction="left" theme="white" />
          </div>
          <div className="swiper-next cursor-pointer">
            <Arrow direction="right" theme="white" />
          </div>
        </div>
      </Swiper>
    );
  } else {
    // Single Testimonial
    return (
      <div className="lg:container mx-auto">
        {items.map((item, i) => {
          return <Testimonial key={i} {...item} />;
        })}
      </div>
    );
  }
};

export default Testimonials;
