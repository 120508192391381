import * as React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";
import Arrow from "../components/Arrow";

export const fragment = graphql`
  fragment HeroSection on WpPage_Pagesections_Sections_Hero {
    text
    image {
      ...MediaItem
    }
  }
`;

const scrollToNext = (e) => {
  const hero = e.currentTarget.parentNode.parentNode;
  hero.nextSibling.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

const Hero = ({ text, image }) => {
  return (
    <section className="relative max-h-vpm md:max-h-vp h-[700px] min-h-[300px] flex">
      <div className="absolute inset-0 lg:container mx-auto">
        <div
          className="content max-w-prose md:max-w-3xl absolute z-20 right-24 md:right-0 left-6 md:left-14 bottom-8 md:bottom-16 text-white space-y-3"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <button
          aria-label="Scroll down"
          className="absolute z-20 right-6 md:right-14 bottom-8 md:bottom-16 rounded-full focus:outline-none focus:shadow-lg group"
          onClick={(e) => {
            scrollToNext(e);
          }}
        >
          <Arrow size={28} direction="down" />
        </button>
      </div>
      <div className="absolute inset-0 bg-oxford bg-opacity-60 z-10"></div>

      <Image image={image} classes="object-cover" />
    </section>
  );
};

export default Hero;
