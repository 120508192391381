import * as React from "react";
import Arrow from "../components/Arrow";

const Button = ({ text, icon, size = 10, onClick, url, classes }) => {
  if (url) {
    return (
      <a
        className={`group relative flex items-center focus:outline-none ${classes}`}
        href={url}
      >
        <Arrow size={size} direction={!icon ? "right" : false} icon={icon} />
        <span className="ml-3 font-bold text-lg">{text}</span>
      </a>
    );
  }
  return (
    <button
      className={`group relative flex items-center focus:outline-none ${classes}`}
      onClick={onClick}
    >
      <Arrow size={size} direction={!icon ? "right" : false} icon={icon} />
      <span className="ml-3 font-bold text-lg">{text}</span>
    </button>
  );
};

export default Button;
