import React, { useState } from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";
import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrows } from "@fortawesome/pro-light-svg-icons";

export const fragment = graphql`
  fragment WpContent on WpPage_Pagesections_Sections_ContentWithImage {
    image {
      ...MediaItem
    }
    title
    text
    link {
      target
      title
      url
    }
    pageLink {
      target
      title
      url
    }
    lightbox {
      enable
      label
    }
  }
`;

const ContentWithImage = ({ title, text, link, image, lightbox, pageLink }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  return (
    <>
      <div className="grid md:grid-cols-2 lg:container mx-auto">
        <div className="flex flex-col border-r-2 border-white border-opacity-10">
          <div className="py-12 md:py-16 px-6 md:px-12">
            <h3 className="text-ocean font-bold text-lg mb-8">{title}</h3>
            <div
              className="content md:mr-8 md:max-w-prose"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
        <div
          className={`relative flex py-12 md:py-0 ${
            !lightbox || !pageLink ? "row-span-2" : ""
          }`}
        >
          <Image image={image} />
        </div>
        {link && (
          <div className="row-start-2 row-end-3 md:row-auto mt-auto border-b-2 md:border-b-0 border-t-2 border-white border-opacity-10 border-r-2">
            <ButtonLink
              text={link.title}
              url={link.url}
              classes={`py-6 px-6 md:px-12`}
            />
          </div>
        )}

        {(lightbox || pageLink) && (
          <div className="mt-auto border-t-2 border-white border-opacity-10 hidden md:grid md:grid-cols-2">
            {lightbox && lightbox.enable && (
              <Button
                text={lightbox.label}
                icon={
                  <FontAwesomeIcon
                    icon={faExpandArrows}
                    className={`text-xl`}
                  />
                }
                onClick={() => {
                  setPopupOpen(true);
                }}
                classes={`group py-6 px-6 md:px-12 hover-hover:hover:bg-ocean`}
              />
            )}
            {pageLink && pageLink.url && (
              <ButtonLink
                text={pageLink.title}
                url={pageLink.url}
                classes={`py-6 px-6 md:px-12`}
              />
            )}
          </div>
        )}
      </div>
      {lightbox && lightbox.enable && (
        <div
          className={`lb fixed inset-0 z-50 bg-black bg-opacity-60 place-items-center ${
            !popupOpen ? "hidden" : "grid"
          }`}
        >
          <button
            className="container mx-auto"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            <Image image={image} />
          </button>
        </div>
      )}
    </>
  );
};

export default ContentWithImage;
