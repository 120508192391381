import React from "react";
import { graphql } from "gatsby";
import ButtonLink from "../components/ButtonLink";
import Slider from "../components/Slider";

export const fragment = graphql`
  fragment WpContentSlider on WpPage_Pagesections_Sections_ContentWithSlider {
    title
    text
    link {
      target
      title
      url
    }
    slides {
      image {
        ...MediaItem
      }
      link {
        title
        url
        target
      }
    }
    lightbox {
      enable
      label
    }
    order
  }
`;

const ContentWithSlider = ({
  title,
  text,
  link,
  slides,
  lightbox,
  order = 1,
}) => {
  return (
    <div className="grid md:grid-cols-2 lg:container mx-auto border-t-2 border-white border-opacity-10">
      <div className={`flex flex-col ${order ? "md:order-2" : "md:order-1"}`}>
        <div className="py-8 md:py-16 px-8 md:px-12">
          <h3 className="text-ocean font-bold text-lg mb-8">{title}</h3>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>

        {link && (
          <div className="mt-auto border-t-2 border-white border-opacity-10">
            <ButtonLink
              text={link.title}
              url={link.url}
              classes={`py-6 px-6 md:px-12`}
            />
          </div>
        )}
      </div>
      <div className={`relative h-full max-w-screen md:order-${order}`}>
        <Slider slides={slides} lightbox={lightbox} />
      </div>
    </div>
  );
};

export default ContentWithSlider;
